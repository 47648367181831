import './topbar.scss';
import { LocationOn, Mail } from '@material-ui/icons';

export default function Topbar({ menuOpen, setMenuOpen }) {
	return (
		<div className={'topbar ' + (menuOpen && 'active')}>
			<div className='wrapper'>
				<div className='left'>
					<a href='#intro' className='logo'>
						Michael O'Reilly
					</a>
					<div className='itemContainer'>
						<LocationOn className='icon' />
						<span>St. John's, NL</span>
					</div>
					<div className='itemContainer'>
						<Mail className='icon' />
						<span>michael.oreilly@keyin.com</span>
					</div>
				</div>
				<div className='right'>
					<div className='hamburger' onClick={() => setMenuOpen(!menuOpen)}>
						<span className='line1'></span>
						<span className='line2'></span>
						<span className='line3'></span>
					</div>
				</div>
			</div>
		</div>
	);
}
