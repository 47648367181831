export const techStackPortfolio = [
	{
		id: '1',
		title: 'Java',
		img: './assets/java.png',
	  },
	  {
		id: '2',
		title: 'JavaScript',
		img: './assets/javascript.png',
	},
	{
		id: '3',
		title: 'Python',
		img: './assets/python.png',
	},
	{
		id: '4',
		title: 'HTML',
		img: './assets/HTML.png',
	  },
	  {
		id: '5',
		title: 'CSS',
		img: './assets/css.png',
	  },
	// {
	// 	id: '6',
	// 	title: 'Swift',
	// 	img: './assets/swift.png',
	// },
	{
		id: '7',
		title: 'ReactJS',
		img: './assets/React.png',
	},
	{
		id: '8',
		title: 'Jest',
		img: './assets/jest.png',
	},
	{
		id: '9',
		title: 'Maven',
		img: './assets/maven.png',
	},
	{
		id: '9',
		title: 'ExpressJS',
		img: './assets/expressJS.png',
	},
	{
		id: '10',
		title: 'NodeJS',
		img: './assets/nodeJS.png',
	},
	{
		id: '11',
		title: 'Docker',
		img: './assets/Docker.png',
	},
	{
		id: '12',
		title: 'Spring Boot',
		img: './assets/springBoot.png',
	},
	{
		id: '13',
		title: 'Postman',
		img: './assets/postman.png',
	},
	{
		id: '14',
		title: 'AWS',
		img: './assets/aws.png',
	},
	{
		id: '15',
		title: 'MongoDB',
		img: './assets/mongoDB.png',
	},
	{
		id: '16',
		title: 'PostgreSQL',
		img: './assets/postgres.png',
	},
	{
		id: '17',
		title: 'MySQL',
		img: './assets/MySQL.png',
	},
	{
		id: '18',
		title: 'Trello',
		img: './assets/Trello2.png',
	},
	{
		id: '19',
		title: 'Jira',
		img: './assets/jira.png',
	},
	{
		id: '20',
		title: 'asana',
		img: './assets/asana1.png',
	},
	{
		id: '21',
		title: 'GitHub',
		img: './assets/GitHub.png',
	},
	{
		id: '22',
		title: 'GitHubCopilot',
		img: './assets/GitHubCopilot.png',
	},
	// {
	// 	id: '23',
	// 	title: 'PhotoShop',
	// 	img: './assets/Photoshop.png',
	// },
	// {
	// 	id: '24',
	// 	title: 'Lightroom',
	// 	img: './assets/Lightroom.png',
	// },
];

export const certificatesPortfolio = [
	{
		id: '1',
		title: 'API Development',
		img: './assets/APIDevelopment.png',
	},
	{
		id: '2',
		title: 'Blockchain Basics',
		img: './assets/BlockchainPython.png',
	},
	{
		id: '3',
		title: 'Code Foundations',
		img: './assets/CodeFoundationsSkillPath.png',
	},
	{
		id: '4',
		title: 'Learn To Code',
		img: './assets/howToCode.png',
	},
	{
		id: '5',
		title: 'Exam Pro CCP',
		img: './assets/ExamProCCP.png',
	},
	{
		id: '6',
		title: 'Exam Pro Solutions Architect',
		img: './assets/AWSSolArchExamPro.png',
	},
	{
		id: '7',
		title: 'AWS Academy Cloud Developer',
		img: './assets/awsCloudDev5x7.png',
	},
	{
		id: '8',
		title: 'Certifided Cloud Practitioner',
		img: './assets/awsCCP.png',
	},
];

export const designPortfolio = [
	{
		id: '1',
		title: 'Disney Plus',
		img: './assets/DisneyPlus.png',
	},
	{
		id: '2',
		title: 'Mac OS',
		img: './assets/MacOS.png',
	},
	{
		id: '3',
		title: 'Spotify',
		img: './assets/Spotify.png',
	},
];

export const developmentPortfolio = [
	{
		id: '1',
		title: 'TD Landing Page',
		img: './assets/TD.png',
	},
	{
		id: '2',
		title: 'Outlook Landing Page',
		img: './assets/Microsoft.png',
	},
	{
		id: '3',
		title: 'Portfolio',
		img: './assets/portfolio.png',
	},
	{
		id: '4',
		title: 'eCommerce',
		img: './assets/ecommerce.png',
	},
];
