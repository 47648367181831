import './contact.scss';
import React, { useState } from 'react';

export default function Contact() {
	const [message, setMessage] = useState(false);

	const handleSubmit = (e) => {
		e.preventDefault();
		setMessage(true);
	};
	return (
		<div className='contact' id='contact'>
			<div className='left'>
				<img src='assets/shake.svg' alt='' />
			</div>
			<div className='right'>
				<div id="contactMe"><h3>CONTACT INFO</h3></div> 
				<h1 id="myEmail">michael.oreilly@keyin.com</h1>
			</div>
		</div>
	);
}
