import { useEffect, useState } from 'react';
import PortfolioList from '../portfolioList/PortfolioList';
import './portfolio.scss';
import { techStackPortfolio, certificatesPortfolio } from '../../data';

export default function Portfolio() {
	const [selected, setSelected] = useState('techStackPortfolio');
	const [data, setData] = useState([]);

	const list = [
		{
			id: 'techStackPortfolio',
			title: 'Tech-Stack',
		},
		{
			id: 'certificates',
			title: 'Certifications',
		},
	];

	useEffect(() => {
		switch (selected) {
			case 'fullStackPortfolio':
			setData(techStackPortfolio);
			break;
			case 'certificates':
			setData(certificatesPortfolio);
			break;
			default:
				setData(techStackPortfolio);
		}
	}, [selected]);

	return (
		<div className='portfolio' id='portfolio'>
			<h1>Portfolio</h1>
			<ul>
				{list.map((item) => (
					<PortfolioList title={item.title} active={selected === item.id} setSelected={setSelected} id={item.id} />
				))}
			</ul>
			<div className='portfolioContainer'>
				<div className='container'>
					{data.map((d) => (
						<div className='item' id='img1'>
							<img 
							id='img1'
							src={d.img} alt=''
							onclick={enlargeImg}
							// onclick={console.log("image clicked")}
							// onmouseout={resetImg}

							/>
							<h3>{d.title}</h3>
						</div>
					))}
				</div>
			</div>
		</div>
	);
}

// function enlargeIamge() {
// 	const img = document.getElementById('itemImage');
// 	img.style.width = '200%';
// 	img.style.height = '200%';
// 	console.log("clicked");
// 	console.log(img);
// }

      // Get the img object using its Id
      let img = document.getElementById('#img1');
      // Function to increase image size
      function enlargeImg() {
        // Set image size to 1.5 times original
        img.style.transform = "scale(2.5)";
        // Animation effect
        img.style.transition = "transform 0.25s ease";
      }
    //   // Function to reset image size
    //   function resetImg() {
    //     // Set image size to original
    //     img.style.transform = "scale(1)";
    //     img.style.transition = "transform 0.25s ease";
    //   }

























